import React from "react"
import { graphql } from "gatsby"
import { ButtonBack } from "../components/ButtonBack/ButtonBack"
import { CustomBloc } from "../components/CustomBloc"
import { Title } from "../components/Title"
import { useIsMobile } from "../components/CustomLayout"
import { getTheme } from "../theme"
import { Flex } from "../components/Flex"
import Image from "../components/image"
import { RDV } from "../components/RDV"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from 'prismic-reactjs';


const BlockImg = ({
  key,
  title,
  isMobile,
  description,
  isFirst = false,
  image = false,
}) => {
  return (
    <>
      <CustomBloc
        style={{
          backgroundColor: getTheme().primaryBackgroundColor07,
          position: "relative",
        }}
        key={key}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ marginTop: 5, width: "100%", maxWidth: 800 }}>
            <Flex style={{ flexDirection: "column", alignItems: "flex-start" }}>
              {isFirst ? (
                <>
                  <Flex style={{ alignItems: "center", marginBottom: 20 }}>
                    <ButtonBack />
                    <Title style={{ margin: 0, marginLeft: 15, }}>{title}</Title>
                  </Flex>
                </>
              ) : (
                <Title>{title}</Title>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "center" : "flex-start",
                  alignItems: isMobile ? "center" : "unset",
                  width: "100%",
                }}
              >
                {image &&
                  // @ts-ignore
                  image.url && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 0,
                        marginBottom: isMobile ? 15 : 10,
                      }}
                    >
                      <Image
                        height={isMobile ? "auto" : "300px"}
                        width={isMobile ? "90%" : "200px"}
                        // @ts-ignore
                        src={image && image.url}
                        id="primary"
                        style={{
                          // @ts-ignore
                          objectFit: "cover",
                          borderRadius: 3,
                          marginRight: isMobile ? "inherit" : 20
                        }}
                      />
                    </div>
                  )}
                <div
                  style={{
                    textAlign: 'justify',
                    maxWidth: isMobile ? "90%" : image.url ? "75%" : "unset",
                    padding: isMobile ? "unset" : "0 5px"
                  }}
                >
                  <RichText render={description} />
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </CustomBloc>
    </>

  )
}

const Guide = props => {
  const { data } = props.data.prismicGuide
  const { image } = props.data.prismicHomepage.data || {}
  const { guide_block } = data
  const isMobile = useIsMobile()
  // Remove this shit when boolean prismic won't break graphql query
  const isMentionsLegalesPageId = props.location.pathname.includes(
    "mentions-legales"
  )
  const seoTitle = data.guide_block[0].block_title && data.guide_block[0].block_title.text
  return (
    <>
      <SEO title={seoTitle} />
      <div
        style={{
          fontSize: 17,
          color: `#2e3f6d`,
          backgroundImage: `url(${(image || {}).url})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {guide_block.map(
          ({ block_title, block_description, block_image }, index) => {
            return (
              <BlockImg
                key={`${block_title.text} ${index}`}
                isMobile={isMobile}
                title={block_title.text}
                description={block_description.raw}
                image={block_image}
                isFirst={index === 0}
              />
            )
          }
        )}
        <Flex
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: getTheme().primaryBackgroundColor07,

          }}
        >
          {!isMentionsLegalesPageId && <RDV />}
        </Flex>
      </div>
    </>
  )
}

export default withPreview(Guide)

export const pageQuery = graphql`
  query GuideBySlug($uid: String!) {
    prismicHomepage {
      data {
        image {
          url
        }
      }
    }
    prismicGuide(uid: { eq: $uid }) {
      uid
      data {
        guide_block {
          block_title {
            text
          }
          block_description {
            raw
          }
          block_image {
            url
            fluid(maxWidth: 1240) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`
